import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>Mail Here</h3>
                            <p><a href="mailto:dhaar.hr@gmail.com">dhaar.hr@gmail.com</a></p>
                            <p><a href="mailto:contact@dhaarlabs.com">contact@dhaarlabs.com</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>Visit Here</h3>
                            <p>BTM 2nd Stage, </p> 
							<p>Bangalore Karnataka 560076</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Phone />
                            </div>
                            <h3>Call Here</h3>
                            <p><a href="tel:9999999999">+91 9999999999</a></p>
                            {/* <p><a href="tel:+2414524526">+241 452 4526</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  
